import { User } from '../../features/auth/types';
import { normalizeToCamelKeys } from '../../helpers/utils';
import apiSlice from '../apiSlice';
import { Product } from '../catalog/types';
import {
  CartItem,
  AddToCartRequest,
  DeleteFromCartRequest,
  UpdateCartRequest,
  CreateOrderRequest,
  Order,
  GetOrderByIdRequest,
  SyncCartRequest,
  AddVehicleForUserRequest,
  MyCar,
  DeleteUserVehicleRequest,
  EditVehicleForUserRequest,
  AddToFavorites,
  DelayedProduct,
  EditUserInfoRequest,
  OrderResponse, Pickpoint,
} from './types';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation<CartItem, AddToCartRequest>({
      query: ({
        itemId, qty, price, sum,
      }) => ({
        url: 'user/cart',
        method: 'POST',
        params: {
          itemid: itemId,
          qty,
          price,
          sum,
        },
      }),
    }),
    deleteFromCart: builder.mutation<CartItem, DeleteFromCartRequest>({
      query: ({
        itemId,
      }) => ({
        url: 'user/cart',
        method: 'DELETE',
        params: {
          itemid: itemId,
        },
      }),
    }),
    updateCart: builder.mutation<CartItem, UpdateCartRequest>({
      query: ({
        itemId, qty,
      }) => ({
        url: 'user/cart',
        method: 'PUT',
        params: {
          itemid: itemId,
          qty,
        },
      }),
    }),
    syncCart: builder.mutation<CartItem[], SyncCartRequest>({
      query: ({ items }) => ({
        url: 'user/cart-sync',
        method: 'POST',
        body: items,
      }),
    }),
    getCart: builder.query<CartItem[], undefined>({
      query: () => 'user/cart',
      providesTags: ['Cart'],
      transformResponse: (response: CartItem[]) => (
        response.map((item) => ({
          ...item,
          item: normalizeToCamelKeys(item.item),
        }))
      ),
    }),
    createOrder: builder.mutation<any, CreateOrderRequest>({
      query: ({
        deliveryType,
        paymentType,
        deliveryAddress,
        deliveryPhone,
        sourceType,
        items,
        userData,
      }) => ({
        url: 'user/order',
        method: 'POST',
        body: {
          'delivery-type': deliveryType,
          'payment-type': paymentType,
          'delivery-address': deliveryAddress,
          'delivery-phone': deliveryPhone,
          items,
          'user-data': userData,
          'source-type': sourceType,
        },
      }),
      invalidatesTags: ['Products'],
    }),
    getOrders: builder.query<OrderResponse, { isArchive: boolean, page: string }>({
      query: ({ isArchive, page }) => `user/orders?pn=${page}&cnt=10${isArchive ? '&archive=true' : ''}`,
      transformResponse: (response: OrderResponse) => normalizeToCamelKeys<OrderResponse>(response),
      providesTags: ['Order'],
    }),
    getOrderById: builder.query<Order, GetOrderByIdRequest>({
      query: ({ orderId }) => `user/order/${orderId}`,
      transformResponse: (response: Order) => normalizeToCamelKeys<Order>(response),
    }),
    deleteOrder: builder.mutation<Order, GetOrderByIdRequest>({
      query: ({ orderId }) => ({
        url: `user/order/${orderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order'],
    }),
    getUserVehicles: builder.query<MyCar[], undefined>({
      query: () => 'user/vehicles',
      transformResponse: (response: MyCar[]) => response.map((item) => ({
        ...item,
        ...(item.model && {
          model:
          {
            ...item.model,
            years: Array.from({
              // @ts-ignore
              length: item.model['end-year'] - item.model['start-year'] + 1,
              // @ts-ignore
            }, (_, index) => item.model['start-year'] as number + index),
          },
        }),
      })),
      providesTags: ['Vehicle'],
    }),
    addVehicleForUser: builder.mutation<MyCar, AddVehicleForUserRequest>({
      query: ({
        brandId,
        modelId,
        engineId,
        year,
        vin,
        regNumber,
      }) => ({
        url: 'user/vehicles',
        method: 'POST',
        body: {
          'brand-id': brandId,
          'model-id': modelId,
          'engine-id': engineId,
          'reg-number': regNumber,
          year,
          vin,
        },
      }),
      invalidatesTags: ['Vehicle'],
    }),
    deleteUserVehicle: builder.mutation<MyCar[], DeleteUserVehicleRequest>({
      query: ({ vehicleId }) => ({
        url: `user/vehicles/${vehicleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vehicle'],
    }),
    editVehicleForUser: builder.mutation<MyCar, EditVehicleForUserRequest>({
      query: ({
        vehicleId,
        brandId,
        modelId,
        engineId,
        year,
        vin,
        regNumber,
      }) => ({
        url: `user/vehicles/${vehicleId}`,
        method: 'PUT',
        body: {
          'brand-id': brandId,
          'model-id': modelId,
          'engine-id': engineId,
          'reg-number': regNumber,
          year,
          vin,
        },
      }),
      invalidatesTags: ['Vehicle'],
    }),
    getDelayed: builder.query<DelayedProduct[], undefined>({
      query: () => 'user/favorites',
      transformResponse: (response: DelayedProduct[]) => (
        response.map((item) => ({
          ...item,
          item: normalizeToCamelKeys<Product>(item.item),
        }))
      ),
      providesTags: ['Favorites'],
    }),
    addToDelayed: builder.mutation<DelayedProduct, AddToFavorites>({
      query: ({ itemId }) => ({
        url: 'user/favorites',
        method: 'POST',
        params: {
          itemId,
        },
      }),
      invalidatesTags: ['Favorites'],
    }),
    deleteFromDelayed: builder.mutation<DelayedProduct, AddToFavorites>({
      query: ({ itemId }) => ({
        url: 'user/favorites',
        method: 'DELETE',
        params: {
          itemId,
        },
      }),
      invalidatesTags: ['Favorites'],
    }),
    sendCode: builder.mutation<string, string>({
      query: (login: string) => ({
        url: 'user/send-code',
        method: 'POST',
        params: { login },
      }),
    }),
    checkCode: builder.mutation<string, { code: string, login: string }>({
      query: ({ code, login }) => ({
        url: 'user/check-code',
        method: 'POST',
        params: { code, login },
      }),
    }),
    editUserInfo: builder.mutation<User, EditUserInfoRequest>({
      query: ({
        name, email, phone, saveVehicle,
      }) => ({
        url: 'user/edit',
        method: 'PUT',
        body: {
          name,
          email,
          phone,
          'save-vehicle': saveVehicle,
        },
      }),
      transformResponse: (response) => normalizeToCamelKeys(response),
    }),
    changePassword: builder.mutation<null, string>({
      query: (password) => ({
        url: 'user/change-password',
        method: 'PUT',
        params: { password },
      }),
    }),
    getPickpointAvailableQuantity: builder.mutation<Pickpoint, null>({
      query: () => ({ url: 'service/pickpoint-cells', method: 'GET' }),
      transformResponse: (response: Pickpoint) => normalizeToCamelKeys<Pickpoint>(response),
    }),
  }),
  overrideExisting: true,
});

export const {
  useAddToCartMutation,
  useGetCartQuery,
  useDeleteFromCartMutation,
  useUpdateCartMutation,
  useCreateOrderMutation,
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useSyncCartMutation,
  useDeleteOrderMutation,
  useGetUserVehiclesQuery,
  useAddVehicleForUserMutation,
  useDeleteUserVehicleMutation,
  useEditVehicleForUserMutation,
  useGetDelayedQuery,
  useAddToDelayedMutation,
  useDeleteFromDelayedMutation,
  useSendCodeMutation,
  useCheckCodeMutation,
  useEditUserInfoMutation,
  useChangePasswordMutation,
  useGetPickpointAvailableQuantityMutation,
} = userApiSlice;
