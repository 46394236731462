import {
  BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logoutAction, setToken } from '../features/auth/auth';
import { normalizeToCamelKeys } from '../helpers/utils';

const baseQuery = fetchBaseQuery({
  // Сервер для боевого сайта
  // baseUrl: 'https://api.safary42.ru:49201/api/',
  // Сервер для dev.safari42.ru
  baseUrl: 'https://api.safary42.ru:49201/api/',
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { token } = (getState()).auth;

    if (token) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { token } = api.getState().auth;

  // @ts-ignore
  if (result.error && result.error.originalStatus === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery({
          url: `/auth/${token ? 'refresh-token' : 'anonymous-auth'}`,
          method: token ? 'PUT' : 'POST',
          credentials: 'include',
        }, api, extraOptions);

        if (refreshResult.data) {
          // @ts-ignore
          api.dispatch(setToken(normalizeToCamelKeys(refreshResult.data)));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logoutAction());
          const anonymousResult = await baseQuery({
            url: '/auth/anonymous-auth',
            method: 'POST',
            credentials: 'include',
          }, api, extraOptions);

          if (anonymousResult.data) {
            api.dispatch(setToken(normalizeToCamelKeys(anonymousResult.data)));
            result = await baseQuery(args, api, extraOptions);
          }
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Cart', 'Order', 'Vehicle', 'Favorites', 'Products'],
  endpoints: () => ({}),
});

export default apiSlice;
